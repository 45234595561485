import Form from '@bluheadless/ui-logic/src/components/form/form'
import MUIGrid from '@mui/material/Grid'
import { styled } from '@mui/system'
import Typography from '../../atoms/typography'

const shouldForwardProp = (prop) => !['variant'].includes(prop)

const FormStyled = styled(Form, { shouldForwardProp })`
	width: 100%;
	max-width: 720px;
`

const Title = styled(Typography)`
	margin-bottom: var(--spacing-3);
`

const Subtitle = styled(Typography)`
	margin-bottom: var(--spacing-4);
	max-width: 60%;
	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 100%;
	}
`

const Wrapper = styled(MUIGrid, { shouldForwardProp })`
	margin: 0 auto;
	${({ variant }) => (variant === 'standard' ? 'flex-wrap: nowrap' : null)};
	${({ variant }) =>
		variant === 'standard' ? 'justify-content: space-around;' : 'flex-wrap: nowrap; flex-direction: column'};
	align-items: flex-start;
`

const TitleWrapper = styled('div', { shouldForwardProp })`
	max-width: 420px;
	${({ variant }) => (variant === 'standard' ? 'margin-right: 50px;' : null)};
`

export { FormStyled, Title, Subtitle, Wrapper, TitleWrapper }
