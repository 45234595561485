import { styled } from '@mui/system'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Link from '../../molecules/link'
import Accordion from '../../molecules/accordion'
import Typography from '../../atoms/typography'

const Wrapper = styled('div')`
	flex-grow: 1;
	order: 2;
	border: none;
	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		order: 1;
	}
	& .MuiPaper-root {
		background: none;
		border: none;
		${({ theme }) => theme.breakpoints.up('md')} {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			border: none;
		}
		& .MuiAccordionSummary-root {
			min-height: auto;
			padding: var(--spacing-2) var(--spacing-1);
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};

			${({ theme }) => theme.breakpoints.up('md')} {
				padding: 0;
				margin-bottom: 30px;
				border-bottom: none;
			}
		}
		& .MuiAccordionSummary-expandIconWrapper {
			color: ${({ theme }) => theme.palette.primary.main};
			svg {
				font-size: 22px;
			}
			${({ theme }) => theme.breakpoints.up('md')} {
				display: none;
			}
		}
		& .MuiAccordionDetails-root {
			padding: var(--spacing-3) var(--spacing-1) var(--spacing-2);
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};

			${({ theme }) => theme.breakpoints.up('md')} {
				padding: 0;
				border-bottom: none;
			}
		}
	}
`

const ListStyled = styled(List)`
	display: flex;
	flex-direction: column;
`

const ListItemStyled = styled(ListItem)`
	flex-direction: column;
	align-items: flex-start;
`

const NavLink = styled(Link)`
	color: ${({ theme }) => theme.palette.primary.main};
	padding-bottom: 20px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: 22px;
		padding-bottom: 0;
	}

	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 1px;
		background-color: var(--color-primary);
		margin-top: 1px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}
	&:hover {
		text-decoration: none;
		&:after {
			width: 100%;
		}
	}
`

const AccordionStyled = styled(Accordion)`
	${({ theme }) => theme.breakpoints.up('md')} {
		& .MuiCollapse-root {
			transition-duration: 0ms !important;
		}
		& .MuiAccordionSummary-content {
			cursor: text;
		}
	}
`

const TypographyStyled = styled(Typography)`
	color: ${({ theme }) => theme.palette.white.main};
	display: flex;
	flex-grow: 1;
	padding: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0;
	}
`

export { Wrapper, ListStyled, ListItemStyled, NavLink, AccordionStyled, TypographyStyled }
