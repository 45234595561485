/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const GlobeIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<g fill="none" strokeWidth={0.7} strokeLinecap="round" strokeLinejoin="round" stroke="currentColor">
			<path
				d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm0 0"
				strokeWidth={1.0499999999999998}
			/>
			<path
				d="M13 2.05S16 6 16 12c0 6-3 9.95-3 9.95M11 21.95S8 18 8 12c0-6 3-9.95 3-9.95M2.629 15.5H21.37M2.629 8.5H21.37"
				strokeWidth={1.0499999999999998}
			/>
		</g>
	</svg>
))
GlobeIconSvg.displayName = 'GlobeIconSvg'

const GlobeIcon = forwardRef((props, ref) => <Icon component={GlobeIconSvg} ref={ref} {...props} />)
GlobeIcon.displayName = 'GlobeIcon'

GlobeIcon.defaultProps = {
	...Icon.defaultProps,
}
GlobeIcon.propTypes = {
	...Icon.propTypes,
}

export default GlobeIcon
export { GlobeIconSvg }
