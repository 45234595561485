import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { any, node, object } from 'prop-types'
import { forwardRef } from 'react'
import CustomExpandIcon from '@/components/ui/atoms/icon/custom-expand-icon'

const Accordion = forwardRef(function Accordion(
	{ expandIcon, title, titleProps, code, content, contentProps, ...props },
	ref
) {
	return (
		<MuiAccordion {...(code && { id: `#${code}` })} {...props} ref={ref}>
			<AccordionSummary expandIcon={expandIcon} {...titleProps}>
				{title}
			</AccordionSummary>
			<AccordionDetails {...contentProps}>{content}</AccordionDetails>
		</MuiAccordion>
	)
})

Accordion.defaultProps = {
	...MuiAccordion.defaultProps,
	defaultExpanded: false,
	disabled: false,
	disableGutters: false,
	square: true,
	elevation: 0,
	expandIcon: <CustomExpandIcon />,
	variant: 'outlined',
}

Accordion.propTypes = {
	...MuiAccordion.propTypes,
	children: any,
	content: any.isRequired,
	expandIcon: node,
	titleProps: object,
	contentProps: object,
}

export default Accordion
