/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const PlusIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M4.912 12.194h7.03m0 0h7.03m-7.03 0V5.136m0 7.058v7.058"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
			strokeWidth={2.337}
		/>
	</svg>
))
PlusIconSvg.displayName = 'PlusIconSvg'

const PlusIcon = forwardRef((props, ref) => <Icon component={PlusIconSvg} ref={ref} {...props} />)
PlusIcon.displayName = 'PlusIcon'

PlusIcon.defaultProps = {
	...Icon.defaultProps,
}
PlusIcon.propTypes = {
	...Icon.propTypes,
}

export default PlusIcon
export { PlusIconSvg }
