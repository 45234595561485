/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const MinusIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M4.912 12.194h7.03m0 0h7.03"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
			strokeWidth={2.337}
		/>
	</svg>
))
MinusIconSvg.displayName = 'MinusIconSvg'

const MinusIcon = forwardRef((props, ref) => <Icon component={MinusIconSvg} ref={ref} {...props} />)
MinusIcon.displayName = 'MinusIcon'

MinusIcon.defaultProps = {
	...Icon.defaultProps,
}
MinusIcon.propTypes = {
	...Icon.propTypes,
}

export default MinusIcon
export { MinusIconSvg }
