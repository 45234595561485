import MinusIcon from '@bluheadless/ui/src/atoms/icons/minus-icon'
import PlusIcon from '@bluheadless/ui/src/atoms/icons/plus-icon'
import Box from '@mui/material/Box'

const CustomExpandIcon = () => {
	return (
		<Box
			sx={{
				'.Mui-expanded & > .expandIconWrapper': {
					display: 'none',
				},
				'.collapseIconWrapper': {
					display: 'none',
				},
				'.Mui-expanded & > .collapseIconWrapper': {
					display: 'block',
				},
			}}
		>
			<div className="collapseIconWrapper">
				<MinusIcon />
			</div>
			<div className="expandIconWrapper">
				<PlusIcon />
			</div>
		</Box>
	)
}

export default CustomExpandIcon
